/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cloud-fog': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 13.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0 2a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m10.405-9.473a5.001 5.001 0 00-9.499-1.004A3.5 3.5 0 103.5 12H13a3 3 0 00.405-5.973M8.5 3a4 4 0 013.976 3.555.5.5 0 00.5.445H13a2 2 0 010 4H3.5a2.5 2.5 0 11.605-4.926.5.5 0 00.596-.329A4 4 0 018.5 3"/>',
    },
});
